<template>
  <div class="box" ref="box">
    <h1 class="h1_title">学员感言</h1>
    <div class="nav">
      <nav v-for="item in datas" :key="item.id" @click="pusharticle(item)">
        <div class="left_img"><img :src="baseUrl+item.thumb" alt=""></div>
        <div class="right_text">
          <div class="top">
            <p><span>代用名</span><span>{{item.author}}</span></p>
            <p><span>所修课程：</span><span>{{item.title}}</span></p>
          </div>
          <div class="bottom">
            <p>{{item.description}}</p>
          </div>
        </div>
      </nav>
    </div>
    <!-- 底部分页,-->
      <div class="pagination">
        <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
  </div>
  <signUp class="signUp"></signUp>
</template>

<script setup>
import signUp from '@/components/phonesignUp.vue'
import Pagination from '@/components/Pagination.vue'
import {ref,onMounted, reactive, nextTick} from 'vue'
import { useRouter,useRoute, } from 'vue-router'
import base64_ from '@/hooks/useBase64text.js'
import { get_article_list } from '@/api/API.js'

const baseUrl = process.env.VUE_APP_BASE_URL
const router = useRouter()
const route = useRoute()
let routeData = JSON.parse(base64_.decode(route.params.obj))

let from = reactive({
  total:0,
  page:1,
  limit:10,
  type_id:routeData.type_id
})
let datas = ref('')
let box = ref("box")
let getStugylist = async (obj) => {
  const {data:res} = await get_article_list(obj)
  if(res.code == 200){
    from.total = res.data.total
    datas.value = res.data.data
    setTimeout(() => {
    box.value.style.transform = 'translateY(0)'
    box.value.style.opacity = '1'
    },500)
  }
}
onMounted(() => {
  from.type_id = routeData.type_id
  getStugylist(from)
})
// 分页
let pageChanges = (e) => {
  from.page = e
  getStugylist(from)
}
// 点击列表跳转详情
const pusharticle = (item) => {
  router.push({
    name:"Article_xq_time",
    params:{obj:base64_.encode(JSON.stringify({
      article_wz_id:item.id,
      title1:routeData.title1,
      title2:item.type_name,
      id:routeData.id,
      type_id:item.id,
      index:5,
      isBanner_nav_id:item.id,
    }))}
  })
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.box{
  margin: 0 20/@maxw;
  transform: translateY(800px);
  opacity: 0;
  transition: ease .8s;
  width: 100%;
   .h1_title{
    box-sizing: border-box;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
    margin: 0;
    padding: 0 0 30/@maxw 0;
  }
  .nav{
    cursor: pointer;
    margin-top: 46/@maxw;
    width: 100%;
    >nav{
      box-sizing: border-box;
      border-bottom: 2px solid #D9D9D9;
      width: 100%;
      height: 319/@maxw;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 47/@maxw 0;
      .left_img{
        width: 190/@maxw;
        height: 190/@maxw;
        background-color: rgba(0, 0, 0, 0.418);
        border-radius: 50%;
        margin-left: 20/@maxw;
        position: relative;
        z-index: 9999999999;
        overflow: hidden;
        img{
          width: 100%;
          height: 100%;
          transition: ease .3s;
        }
      }
      .right_text{
        width: 870/@maxw;
        height: 220/@maxw;
        position: relative;
        >.top{
          p{
            line-height: 41/@maxw;
            margin: 0;padding: 0;
            font-size: 24/@maxw;
            transition: ease .3s;
          }
          >p:first-child{
            font-weight: 500;
            color: #174994;
            >span:first-child{margin-right: 72/@maxw;}
          }
          >p:last-child{
            >span:first-child{color: #5F5F5F;font-weight: 500;margin-right: 25/@maxw;}
            >span:last-child{color: #B19A86;font-weight: bolder;}
          }
        }
        >.bottom{
          width: 108%;
          height: 120/@maxw;
          background-image: url("../../../assets/studentgyBackg.png");
          background-repeat: no-repeat;
          background-size: 100% 100%;
          position: absolute;
          right: 0;
          bottom: 0;
          z-index: 99999;
          >p{
            width: 760/@maxw;
            height: 52/@maxw;
            margin: 0;
            font-size: 22/@maxw;
            color: #5F5F5F;
            line-height: 37/@maxw;
            margin: 22/@maxw 0 0 68/@maxw;
          }
        }
      }
    }
    >nav:hover{
      >.left_img{
        img{
          transform: scale(1.05);
        }
      }
      >.right_text{
        .top{
          p{color: #8aafff;}
        }
      }
    }
    >nav:nth-child(2n){
      .left_img{
        margin-left: 0 !important;
      }
    }
  }
   .pagination{
  // margin: 40/@maxw 0 0 40%; 
  display: flex;
  justify-content: center;
  margin: 8% 0 0 0; 
}
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .signUp{display: none;}
  .box{
  .h1_title{
    font-size: 39/@maxw_;
    border-bottom:2/@maxw_ solid #174994;
    padding: 0 0 30/@maxw_ 0;
  }
  .nav{
    margin-top: 46/@maxw_;
    >nav{
      height: 319/@maxw_;
      padding: 47/@maxw_ 0;
      .left_img{
        width: 300/@maxw_;
        height: 170/@maxw_;
        margin-left: 10/@maxw_;
      }
      .right_text{
        width: 870/@maxw_;
        height: 220/@maxw_;
        >.top{
          p{
            line-height: 30/@maxw_;
            font-size: 24/@maxw_;
          }
          >p:first-child{
            >span:first-child{margin-right: 72/@maxw_;}
          }
          >p:last-child{
            width: 480/@maxw_;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            >span:first-child{color: #5F5F5F;font-weight: 500;margin-right: 25/@maxw_;}
          }
        }
        >.bottom{
          height: 120/@maxw_;
          >p{
            width: 480/@maxw_;
            height: 85/@maxw_;
            font-size: 22/@maxw_;
            line-height: 30/@maxw_;
            margin: 22/@maxw_ 0 0 68/@maxw_;
            overflow:hidden;
            text-overflow:ellipsis;
            display:-webkit-box;
            -webkit-box-orient:vertical;
            -webkit-line-clamp:3; // 想要超出三行显示 就把这里改成3就好了
          }
        }
      }
    }
  }
  }
}
</style>