<template>
<!-- 右侧电话和报名 -->
    <div class="right_float">
      <nav>
        <div class="phoneNum">
          <span>详情请咨询：</span>
          <a href="javascript:;">010-82680770</a>
        </div>
      </nav>
      <nav @click="onlineReg"></nav>
    </div>
</template>

<script setup>
import {ref,reactive,onMounted} from 'vue'
import { useRouter,useRoute } from 'vue-router'
let router = useRouter()
let route = useRoute()
let routeData = JSON.parse(base64_.decode(route.params.obj))
import base64_ from '@/hooks/useBase64text.js'
let onlineReg = () => {
   router.push({
    name:"OnlineRegistration",
    params:{obj:base64_.encode(JSON.stringify({
      title1:routeData.title1,
      title2:'在线报名',
      id:routeData.id,
      index:6,
      isBanner_nav_id:routeData.isBanner_nav_id,
    }))}
  })
}
</script>

<style lang="less" scoped>
@maxw:128rem;
.right_float{
cursor: pointer;
position: fixed;
top: 705/@maxw;
right: -960/@maxw;
margin-right: 50%;
>nav{
  position: relative;
  margin-bottom: 18/@maxw;
  width: 100/@maxw;
  height: 100/@maxw;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  transition: ease .2s;
}
>nav:first-child{
  background-image: url("https://s1.ax1x.com/2022/08/11/v84oBd.png");
  .phoneNum{
    position: absolute;
    top: 0;
    right: 100/@maxw;
    width: 255/@maxw;
    height: 100%;
    background: linear-gradient(to right,#F0F0F0 0%,#E9E9E9 30%,#C6C6C6 100%);
    transition: ease .3s;
    transform: scale(0);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    color: #174994;
    box-sizing: border-box;
    padding-left: 22/@maxw;
    >span{
      font-size: 17/@maxw;
    } 
    >a{
      font-size: 31/@maxw;
      color: #174994;
    }
  }
}
  >nav:first-child:hover{
    .phoneNum{transform: scale(1);}
  }
>nav:last-child{
  background-image: url("https://s1.ax1x.com/2022/08/11/v84q4P.png");
}
>nav:hover{
  transform: scale(1.06);
}
}
</style>